import TeamImage from "@/app/pwa/components/TeamImage";
import { Team } from "@/sources/kicker-amateur/types";
import { TeamName } from "@/app/pwa/components/team/TeamName";
import { Table, TableColumn } from "../Table";

export interface BaseTeamTableProps {
  teamId: number;
  opponentId?: number;
  contestId?: number;
}
export interface TeamTableProps extends BaseTeamTableProps {
  table: any;
}

interface TeamTableColumnsConfig {
  teamId: number;
}

const teamTableColumns = ({
  teamId,
}: TeamTableColumnsConfig): TableColumn<any>[] => [
  {
    accessorKey: "Rank",
    header: "#",
    colSpan: 2,
    width: "min-w-[16px] max-w-[16px] !px-0",
    alignment: "text-center",
    cell: (value: any) => (
      <div className="flex items-center gap-x-2 justify-center">
        {/* #TODO:Andrej: data for correct icon */}
        {/* <IconRankUnchanged width={16} height={16} /> */}
        <div className="font-semibold">{value}</div>
      </div>
    ),
  },
  {
    accessorKey: "Team",
    header: <span className="ml-8 pl-2">Verein</span>,
    // colSpan: 2,
    // width: "min-w-[32px] max-w-[32px] !px-0",
    tdWidth: "w-full",
    alignment: "text-left",
    cell: (value: Team) => {
      return (
        <div className="flex items-center gap-x-2 @sm/table:gap-x-2">
          <div className="shrink-0">
            <TeamImage team={value} size="8" />
          </div>
          <div className="hidden @md/table:block">
            <div className="line-clamp-1 font-semibold text-sm @sm/table:text-base break-all group-[.is-row-selected]:underline decoration-team decoration-2 underline-offset-[6px] py-1">
                <TeamName team={value} teamId={teamId} />
            </div>
          </div>
          <div className="line-clamp-1 font-semibold text-sm @sm/table:text-base break-all @md/table:hidden group-[.is-row-selected]:underline decoration-team decoration-2 underline-offset-4 py-1">
            {value.ShortName ?? <TeamName team={value} teamId={teamId} />}
          </div>
        </div>
      );
    },
  },
  { accessorKey: "Matches", header: "Sp." },
  { accessorKey: "Wins", header: "S", responsive: "@lg:table-cell" },
  { accessorKey: "Draws", header: "U", responsive: "@lg:table-cell" },
  { accessorKey: "Losses", header: "N", responsive: "@lg:table-cell" },
  { accessorKey: "GoalRatio", header: "Tore" },
  {
    accessorKey: "GoalDifference",
    header: "Diff.",
    responsive: "@xs:table-cell",
  },
  {
    accessorKey: "Points",
    header: "Pkt.",
    highlight: true,
    alignment: "text-center",
  },
];

export function TeamTable({
  table,
  teamId,
  opponentId,
  contestId,
}: TeamTableProps) {
  return (<>
    {table?.rows && <Table
      // selected={{ value: opponentId ? `${opponentId}` : `${teamId}`, key: "team.id" }}
      selected={{ value: `${teamId}`, key: "Team.Id" }}
      href={(value) => {
        const { Id } = value.Team;
        return `/team/${teamId}${
          Id ? `/opponent/${Id}${contestId ? `/contest/${contestId}` : ""}` : ""
        }`;
      }}
      columns={teamTableColumns({teamId})}
      data={table.rows}
    />}
    </>
  );
}

export interface AsyncTeamTableProps extends BaseTeamTableProps {
  promise: Promise<any>;
}

export async function AsyncTeamTable({
  promise,
  ...rest
}: AsyncTeamTableProps) {
  const table = await promise;
  return <TeamTable table={table} {...rest} />;
}
