import { Card } from "@/app/pwa/components/Card";
import NoContent from "@/app/pwa/components/NoContent";
import { TeamTable } from "@/app/pwa/components/team/TeamTable";
import IconRotate from "@/assets/icon/rotate.svg";
import { TeamDashboardData } from "@/sources/kicker-amateur/api/contest";

export function TeamTableLoader({ range }: { range?: number }) {
  return (
    <div className="loader space-y-3.5 @container">
      <div className="flex px-4 space-x-4 items-center justify-between">
        <div className="flex flex-1 space-x-2 items-center">
          <div className="h-4 w-10 loader__surface rounded"></div>
          <div className="h-4 w-8 loader__surface rounded"></div>
          <div className="h-4 flex-1 max-w-12 loader__surface rounded"></div>
        </div>

        <div className="flex gap-4">
          <div className="h-4 w-5 loader__surface rounded hidden @xs:block"></div>
          <div className="h-4 w-5 loader__surface rounded"></div>
          <div className="h-4 w-5 loader__surface rounded"></div>
          <div className="h-4 w-5 loader__surface rounded"></div>
        </div>
      </div>
      <div className="loader__surface py-px rounded"></div>
      {[...Array(range ? range * 2 + 1 : 18)].map((e, i) => (
        <div
          className="flex px-4 space-x-4 items-center justify-between"
          key={`loader-item-${i}`}
        >
          <div className="flex flex-1 space-x-2 items-center">
            <div className="h-2 w-10 loader__surface rounded"></div>
            <div className="h-8 w-8 loader__surface rounded-full"></div>
            <div className="h-4 flex-1 loader__surface rounded"></div>
          </div>

          <div className="flex gap-4">
            <div className="h-4 w-5 loader__surface rounded hidden @xs:block"></div>
            <div className="h-4 w-5 loader__surface rounded"></div>
            <div className="h-4 w-5 loader__surface rounded"></div>
            <div className="h-4 w-5 loader__surface rounded"></div>
          </div>
        </div>
      ))}
    </div>
  );
}

export async function TeamTablePage({
  promise,
  teamId,
  contestId,
  showHeader = true,
}: {
  promise: Promise<TeamDashboardData>;
  teamId: number;
  contestId?: number;
  showHeader?: boolean
}) {
  const { contests, table } = await promise;
  contestId = contestId
    ? contestId
    : table.league
    ? table.league.Id
    : undefined;

  if (table.rows.length === 0) {
    return <NoContent>Keine Tabelle verfügbar</NoContent>
  }

  return (
    <div className="@container/page">
      <Card p={false}>
        { showHeader && table.league && 
          <div className="text-center border-b border-gray-150 px-4 py-3">
            <h3 className="text-lg font-bold">{table.league.Name}</h3>
            {table.league?.CurrentRound?.Name}
          </div>
        }
        <div className={`border-2 border-transparent ${showHeader ? "mt-2" : ""}`}>
          <TeamTable table={table} teamId={teamId} contestId={contestId} />
        </div>
      </Card>
      <div className="text-center flex items-center justify-center space-x-2 font-condensed text-gray-900 text-sm mt-8 @lg/page:hidden">
        <IconRotate width={14} height={14} />
        <span>Rotiere dein Gerät für vollständige Tabelle</span>
      </div>
    </div>
  );
}
