import { ReactNode } from "react";

export const Card = ({
  children,
  header,
  className,
  isContainer = true,
  p = 4,
  bg = "bg-surface",
  id,
}: {
  bg?: string;
  children: ReactNode;
  header?: ReactNode;
  className?: string;
  isContainer?: boolean;
  p?: 4 | false;
  id?: string;
}) => {
  const paddingClassName = p == 4 ? "p-4" : "";

  return (
    <div
      className={`${bg} text-gray-900 rounded-md ${isContainer ? "@container/card" : ""} ${
        className || ""
      } ${paddingClassName}`}
      id={id}
    >
      {header && (
        <div className="px-4 py-3 border-b border-gray-150 ">{header}</div>
      )}
      {children}
    </div>
  );
};

export function CardHeader({
  className,
  children,
  ...attributes
}: {
  className?: string;
  children?: React.ReactNode;
}) {
  return (
    <div
      className={`px-4 py-3 border-b border-b-gray-150 ${className || ""}`}
      {...attributes}
    >
      {children}
    </div>
  );
}

export function CardHeaderWithTitle({
  title,
  ...attributes
}: {
  title?: React.ReactNode;
  className?: string;
}) {
  return (
    <CardHeader {...attributes}>
      {title && <h3 className="text-lg font-semibold">{title}</h3>}
    </CardHeader>
  );
}
