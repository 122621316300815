import Link from "next/link";
import React from "react";


type TableCellFormatter<T> = (value: any) => React.ReactNode;

export interface TableColumn<T> {
  accessorKey: string;
  header: React.ReactNode;
  colSpan?: number;
  width?: string;
  tdWidth?: string;
  alignment?: "text-left" | "text-center" | "text-right" | undefined;
  highlight?: boolean;
  responsive?:
    | "@xs:table-cell"
    | "@sm:table-cell"
    | "@md:table-cell"
    | "@lg:table-cell";
  cell?: TableCellFormatter<T>;
}

type TableRow = {
  empty?: boolean;
};

interface SelectedRow {
  value: string;
  key: string;
}

interface TableProps<T extends TableRow> {
  data: T[];
  columns: TableColumn<T>[];
  href?: (value: any) => string;
  selected?: SelectedRow;
}

function getNestedValue(row: any, accessorKey: string) {
  return accessorKey
    .split(".")
    .reduce(
      (obj, key) => (obj && obj[key] !== "undefined" ? obj[key] : undefined),
      row
    );
}

export function Table<T extends TableRow>({
  data,
  columns,
  selected,
  href,
}: TableProps<T>) {
  const tdPadding = "py-2 pr-2 first:pl-2 @lg/table:pr-4 @lg/table:first:pl-4";

  let maxColSpan = columns.reduce((sum, column) => {
    return sum + (column.colSpan || 1);
  }, 0);

  return (
    <div className="@container/table table-container">
      <table className="w-full max-w-full table-auto">
        <thead>
          <tr>
            {columns &&
              columns.map((column, index) => {
                const className = `
                  uppercase
                  ${tdPadding} ${column.alignment || "text-center"}
                `;
                return (
                  <React.Fragment key={`table-${index}`}>
                    {column.colSpan &&
                      column.colSpan > 1 &&
                      [...Array(column.colSpan! - 1)].map((_, i) => (
                        <th
                          key={i}
                          className={`${tdPadding} ${column.width} ${
                            column.responsive
                              ? `hidden ${column.responsive}`
                              : ""
                          }`}
                        />
                      ))}
                    <th
                      key={index}
                      className={`text-sm ${
                        column.responsive ? `hidden ${column.responsive}` : ""
                      } ${className || ""}`}
                    >
                      {column.header}
                    </th>
                  </React.Fragment>
                );
              })}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => {
            const isRowSelected = selected
              ? `${getNestedValue(row, selected.key)}` === `${selected.value}`
              : false;

            return (
              <tr
                key={rowIndex}
                className={`
                  odd:bg-gray-100
                  ${rowIndex % 2 === 0 ? "bg-gray-100" : ""}
                  group
                  ${isRowSelected ? "is-row-selected" : ""}
                `}
              >
                {row.empty ? (
                  <td
                    colSpan={maxColSpan}
                    className="border-white px-4 py-3 text-center"
                  >
                    <div className="flex flex-col items-center gap-1">
                      <div className="bg-gray-200 rounded-full h-1 w-1"></div>
                      <div className="bg-gray-200 rounded-full h-1 w-1"></div>
                      <div className="bg-gray-200 rounded-full h-1 w-1"></div>
                    </div>
                  </td>
                ) : (
                  columns.map((column, colIndex) => {
                    const cellValue = getNestedValue(row, column.accessorKey);
                    const Cell = column.cell
                      ? column.cell(cellValue)
                      : cellValue;

                    return (
                      <td
                        key={colIndex}
                        className={` text-base
                        ${column.tdWidth || "" }
                        ${column.highlight ? "text-primary font-bold" : ""}
                        ${tdPadding}
                        ${column.alignment || "text-center"}
                        ${
                          column.responsive ? `hidden ${column.responsive}` : ""
                        }
                      `}
                        colSpan={column.colSpan}
                      >
                        {href ? (
                          <Link href={href(row)} className="block">
                            {Cell}
                          </Link>
                        ) : (
                          Cell
                        )}
                      </td>
                    );
                  })
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
