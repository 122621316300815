"use client";
import React, { useState, useEffect } from "react";
import { ContestTableData } from "@/sources/kicker-amateur/api/contest";
import { TeamTable } from "./TeamTable";
import Link from "next/link";
import { pwaTeamTablePath } from "@/utils/urlHelper";
import { Card } from "@/app/pwa/components/Card";
import { TeamTableLoader } from "../../(main-layout)/team/[teamId]/(tab-layout)/table/components/TeamTablePage";
import NoContent from "../NoContent";

export interface BaseTeamContestTableProps {
  teamId: number;
  contestId?: number;
  range?: number;
}

export function TeamContestTableImp({
  teamId,
  contestId,
  range,
}: BaseTeamContestTableProps) {
  const [table, setTable] = useState<ContestTableData | undefined>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    let url = `/api/contest/${contestId}/team/${teamId}`;

    if (range) {
      url = `${url}?range=${range}`;
    }

    fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Could not load team ...");
        }
      })
      .then((data) => {
        setTable(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [teamId, contestId, range]);

  if (loading) {
    return <TeamTableLoader range={range} />;
  }

  function renderTable() {
    return <TeamTable table={table} teamId={teamId} contestId={contestId} />;
  }

  return (
    <div>
      {range ? (
        <>
          <div className="relative">
            {renderTable()}
            <Link
              className="absolute inset-0 z-10"
              href={pwaTeamTablePath(teamId.toString())}
            ></Link>
          </div>
          {table && table.rows.length < table.totalCount && (
            <div className="border-t border-gray-150">
              <Link
                className="button button--link-secondary w-full justify-center"
                href={pwaTeamTablePath(teamId.toString())}
              >
                Vollständige Tabelle anzeigen
              </Link>
            </div>
          )}
        </>
      ) : (
        renderTable()
      )}
    </div>
  );
}

export function TeamContestTable(props: BaseTeamContestTableProps) {
  const { teamId, range } = props;
  const [loading, setLoading] = useState(true);
  const [contests, setContests] = useState([]);
  const [contestId, setContestId] = useState<number>();

  useEffect(() => {
    setLoading(true);
    let url = `/api/team/${teamId}/contest`;

    if (range) {
      url = `${url}?range=${range}`;
    }

    fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Could not load contest ...");
        }
      })
      .then((data) => {
        setContests(data);
        if (data[0]) {
          setContestId(data[0].Id);
        }
        setLoading(false);
      })
      .catch((error) => {
        setContests([]);
        setLoading(false);
      });
  }, [teamId, range]);

  if (loading) {
    return <TeamTableLoader range={range} />;
  }

  const handleContestIdChange = (value: number) => {
    setContestId(value);
  };

  return (
    <Card p={false}>
      <div className="flex items-baseline justify-between border-b border-gray-150">
        <h2 className="text-lg font-bold flex-grow px-4 py-3">Tabelle</h2>
      </div>
      <div className="border-2 border-transparent mt-3">
        {contestId && <TeamContestTableImp contestId={contestId} {...props} />}
        {!contestId && <NoContent>Keine Tabelle verfügbar</NoContent>}
      </div>
    </Card>
  );
}
