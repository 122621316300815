import React from "react"

export default function NoContent({className, children} : {className?: string, children: React.ReactNode}) {
  return (
    <div className={`h-[30dvh] p-4 flex flex-col items-center justify-center rounded-lg bg-surface font-compressed text-gray-200 ${className}`}>{children}</div>
  )
}

export function NoContentPlain({className, children} : {className?: string, children: React.ReactNode}) {
  return (
    <div className={`p-4 flex flex-col items-center justify-center rounded font-compressed text-gray-200 ${className}`}>{children}</div>
  )
}
